import { render, staticRenderFns } from "./adReportForms.vue?vue&type=template&id=89bc90da&scoped=true&"
import script from "./adReportForms.vue?vue&type=script&lang=ts&"
export * from "./adReportForms.vue?vue&type=script&lang=ts&"
import style0 from "./adReportForms.vue?vue&type=style&index=0&id=89bc90da&lang=scss&scoped=true&"
import style1 from "./adReportForms.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89bc90da",
  null
  
)

export default component.exports